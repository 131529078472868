import { useState, useEffect, useRef } from 'react'
import useCreadyApi from '../utils/Api';

export default function Chat(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [debtId, setDebtId] = useState(props.debtId);
  const [message, setMessage] = useState('');
  const [canSend, setCanSend] = useState(false);
  const messagesContainerRef = useRef(null);
  const api = useCreadyApi();


  useEffect(() => {
    const fetchData = async () => {
      try {
    const parsedResponse = await api.debts.chats(debtId);
    setData(parsedResponse);

    setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

  }, []);

  useEffect(() => {
    if(message.length > 0){
      setCanSend(true);
    }else if(message.length === 0){
      setCanSend(false);
    }
  }, [message])

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [data.messages]);

  const handleClick = async () => {
    try {
      setCanSend(false);
        const response = await await api.debts.addChatMessage(debtId, {message: message});
        const parsedResponse = await api.debts.chats(debtId);
        setData(parsedResponse);
        setIsLoading(false);
        setMessage('');
      }
    catch (error) {
    }
  };

  if(isLoading){
    return <h1>Laddar chat...</h1>
  }else if(data != null){


    return (
      <div className="pt-16">
        <h2 className="text-m font-semibold leading-6 text-gray-900 pb-4">Chatt</h2>
      <div className="flex flex-col max-h-[500px] w-[495px] ring-1 ring-gray-900/5 rounded-lg p-1.5">
        <div ref={messagesContainerRef} className="overflow-y-scroll pr-4 scroll-smooth flex-col-reverse">
          {data.messages.length > 0 ? (
            data.messages.map((message) => (
              <div key={message.id} className={`flex flex-col mb-2 gap-2 py-1 ${message.isSelf ? 'justify-end' : 'justify-start'}`}>
                <div className={`flex ${message.isSelf ? 'justify-end' : 'justify-start'}`}>
                  <div className={`bg-${message.isSelf ? 'blue-2 bg-opacity-40' : 'gray-100'} rounded-lg px-4 py-2 max-w-[80%]`}>
                    <p className={`relative border ${message.isSelf ? '-top-5 -right-2' : '-top-5 -left-2'} inline-block bg-white px-1 text-xs font-medium text-black`}>
                      {message.name} {message.isCreadyUser ? '(cready)' : ''} - {new Date(message.created).toLocaleDateString("sv-SE")}
                    </p>
                    <p className="text-black text-sm">{message.text}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>Inga meddelanden</p>
          )}
        </div>
        <div className="flex justify-center items-center h-16">
          <textarea onChange={handleInputChange} value={message} type="text" className="border focus:ring-black border-gray-300 text-sm rounded-lg py-2 px-4 w-full max-w-lg mr-4 max-h-20 min-h-20 resize-none" placeholder="Skriv ett meddelande"/>
          <button onClick={() => handleClick()} className="rounded-md bg-white px-3.5 py-2 text-sm font-semibold text-blue-2 shadow-sm ring-2 ring-inset ring-blue-2 hover:bg-neutral-500 hover:bg-opacity-5 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white" disabled={!canSend}>Skicka</button>
        </div>
      </div>
      </div>
    );
    
}
}