import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { formatNumberWithSeparators } from '../utilities';
  export default function InvoiceList(data) {
    return (
      <div className="pt-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Fakturor</h1>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                      Fakturanummer
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Förfallodatum
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Kapitalbelopp
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Resterande kapital
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data.invoices.map((invoice) => (
                    <tr key={invoice.id} className="even:bg-gray-50">
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {invoice.externalClientInvoiceNumber}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{new Date(invoice.dueDate).toLocaleDateString("sv-SE")}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberWithSeparators(invoice.capital)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatNumberWithSeparators(invoice.remainingCapital)}</td>
                    <td>
                    {/* <button
                      type="button"
                      className="inline-flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white"
                      onClick={() =>window.open("/invoice/" + invoice.id, "_blank", "noreferrer")}
                    >
                      <ArrowTopRightOnSquareIcon className='h-4'/>
                    </button> */}
                    </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  